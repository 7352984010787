import React, { FC } from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";

const WeAre: FC = () => {
  const { colorMode } = useColorMode();
  const cardBgColor = { light: "white", dark: "gray.900" };
  const cardColor = { light: "gray.900", dark: "white" };

  const headingNode = () => {
    return (
      <Box pb={4} d="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="xl">
          We Are
        </Heading>
      </Box>
    );
  };

  const titleNode = (title: string) => {
    return (
      <Heading as="h3" size="md">
        {title}
      </Heading>
    );
  };

  return (
    <Stack spacing={4}>
      {headingNode()}
      <Stack spacing={8}>
        <Box
          bg={cardBgColor[colorMode]}
          color={cardColor[colorMode]}
          p={8}
          rounded="md"
          shadow="md"
        >
          <Stack>
            <Box h="100%" as="section">
              <Text fontSize="2xl">A team of senior developers focused on creating results driven software.</Text>
              <Text fontSize="2xl">We constantly innovate, learn, and evolve to provide cutting-edge solutions.</Text>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default WeAre;
